import { FC } from 'react';
import IconProps from './IconProps';

const WebsiteIcon: FC<IconProps> = (props) => {
  const { onClick, className, accentColor = '#C5A76B', gradient } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  if (gradient) {
    return (
      <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.5003 17.0698H20.492C22.746 17.0698 24.5732 15.2426 24.5732 12.9886V6.08114C24.5732 3.82719 22.746 2 20.492 2H7.44503C5.19108 2 3.36389 3.82719 3.36389 6.08114V6.32558"
          stroke="url(#paint0_linear_4183_66131)"
          strokeWidth="2.33208"
          strokeLinecap="round"
        />
        <path
          d="M12.2444 13.9999C12.2444 16.9008 9.89276 19.2525 6.99185 19.2525C4.09094 19.2525 1.73928 16.9008 1.73928 13.9999C1.73928 11.099 4.09094 8.74734 6.99185 8.74734C9.89276 8.74734 12.2444 11.099 12.2444 13.9999Z"
          stroke="url(#paint1_linear_4183_66131)"
          strokeWidth="2.33208"
        />
        <path
          d="M7.64292 16.9174V14.9895H9.41012C9.58285 14.9895 9.74851 14.9146 9.87065 14.7814C9.99279 14.6482 10.0614 14.4674 10.0614 14.279C10.0614 14.0906 9.99279 13.9099 9.87065 13.7766C9.74851 13.6434 9.58285 13.5685 9.41012 13.5685H7.64292V11.6407C7.64292 11.4522 7.57431 11.2715 7.45217 11.1383C7.33002 11.005 7.16437 10.9302 6.99164 10.9302C6.8189 10.9302 6.65325 11.005 6.5311 11.1383C6.40896 11.2715 6.34035 11.4522 6.34035 11.6407V13.5685H4.57316C4.40043 13.5685 4.23477 13.6434 4.11263 13.7766C3.99049 13.9099 3.92188 14.0906 3.92188 14.279C3.92188 14.4675 3.99049 14.6482 4.11263 14.7814C4.23477 14.9147 4.40043 14.9895 4.57316 14.9895H6.34036V16.9174C6.34036 17.1058 6.40898 17.2865 6.53112 17.4198C6.65326 17.553 6.81892 17.6279 6.99165 17.6279C7.16438 17.6279 7.33004 17.553 7.45218 17.4198C7.57432 17.2865 7.64294 17.1058 7.64294 16.9174H7.64292Z"
          fill="url(#paint2_linear_4183_66131)"
        />
        <defs>
          <linearGradient id="paint0_linear_4183_66131" x1="3.36389" y1="6.60146" x2="21.7821" y2="17.6826" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint1_linear_4183_66131" x1="0.573242" y1="11.5011" x2="13.4105" y2="16.9887" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint2_linear_4183_66131" x1="3.92187" y1="12.9753" x2="10.2168" y2="15.442" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      width="27"
      height="21"
      viewBox="0 0 27 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9187 15.9037C15.2747 15.9037 14.7526 16.4258 14.7526 17.0698C14.7526 17.7138 15.2747 18.2358 15.9187 18.2358V15.9037ZM2.61619 6.3256C2.61619 6.96958 3.13824 7.49164 3.78223 7.49164C4.42621 7.49164 4.94827 6.96958 4.94827 6.3256H2.61619ZM7.86337 3.16606H20.9104V0.833974H7.86337V3.16606ZM23.8255 6.08116V12.9886H26.1576V6.08116H23.8255ZM20.9104 15.9037H15.9187V18.2358H20.9104V15.9037ZM4.94827 6.3256V6.08116H2.61619V6.3256H4.94827ZM23.8255 12.9886C23.8255 14.5986 22.5204 15.9037 20.9104 15.9037V18.2358C23.8083 18.2358 26.1576 15.8866 26.1576 12.9886H23.8255ZM20.9104 3.16606C22.5203 3.16606 23.8255 4.47119 23.8255 6.08116H26.1576C26.1576 3.18322 23.8083 0.833974 20.9104 0.833974V3.16606ZM7.86337 0.833974C4.96543 0.833974 2.61619 3.18322 2.61619 6.08116H4.94827C4.94827 4.47119 6.2534 3.16606 7.86337 3.16606V0.833974Z"
        fill="currentColor"
      />
      <path
        d="M12.6624 14C12.6624 16.9009 10.3107 19.2526 7.40982 19.2526C4.5089 19.2526 2.15725 16.9009 2.15725 14C2.15725 11.0991 4.5089 8.74745 7.40982 8.74745C10.3107 8.74745 12.6624 11.0991 12.6624 14Z"
        stroke="currentColor"
        strokeWidth="2.33208"
      />
      <path
        d="M8.06089 16.9174V14.9896H9.82809C10.0008 14.9896 10.1665 14.9147 10.2886 14.7815C10.4108 14.6482 10.4794 14.4675 10.4794 14.2791C10.4794 14.0906 10.4108 13.9099 10.2886 13.7767C10.1665 13.6434 10.0008 13.5686 9.82809 13.5686H8.06089V11.6407C8.06089 11.4523 7.99227 11.2716 7.87013 11.1384C7.74799 11.0051 7.58234 10.9303 7.4096 10.9303C7.23687 10.9303 7.07121 11.0051 6.94907 11.1384C6.82693 11.2716 6.75832 11.4523 6.75832 11.6407V13.5686H4.99113C4.8184 13.5686 4.65274 13.6435 4.5306 13.7767C4.40846 13.9099 4.33984 14.0907 4.33984 14.2791C4.33984 14.4675 4.40846 14.6483 4.5306 14.7815C4.65274 14.9147 4.8184 14.9896 4.99113 14.9896H6.75833V16.9174C6.75833 17.1059 6.82695 17.2866 6.94909 17.4198C7.07123 17.5531 7.23689 17.6279 7.40962 17.6279C7.58235 17.6279 7.74801 17.5531 7.87015 17.4198C7.99229 17.2866 8.06091 17.1059 8.06091 16.9174H8.06089Z"
        fill={accentColor}
      />
    </svg>
  );
};

export default WebsiteIcon;
